export enum FormListEnum {
    Empty = 0,
    Brief = 1,
    InsertionOrderApproval = 2,
    PurchaseOrder = 3,
    DigitalAsset = 4,
    FundingCode = 5,
    CampaignTracker = 6,
    Assign = 7,
    CampaignAssign = 8,
    CampaignSetup = 9,
    CampaignSetupReview = 10,
    ApproveRejectCampaignSetup = 11,
    CaptureAndSendProofOfLive = 12,
    ApproveCampaign = 13,
    RequestCampaignStatus = 14,
    AcknowledgeProofOfLive = 15,
    Validation = 16,
    ImplementationPlan = 17,
    CampaignReview = 18,
    LiveProof = 19,
    CampaignPlan = 20,
    InsertionOrderSign = 21,
}
