import { PageAction } from "components/brief/brief-utils";

export const CommonConstant = {
    BASE_URL: "/",
    defaultCurrency: "R",
    genericErrorMessage: "Something Went Wrong. Please try again Later.",
    noPermission: "You do not have permission to view this page",
    dateFormatWithTime: "DD/MM/YYYY hh:mm A",
    dateFormatUS: "DD/MM/YYYY",
    globalDateFormat: "YYYY-MM-DD",
    reportFormat: "D MMM, YY", //"DD MMM GG", //eg 31 Aug 21
    selectAtleastOne: "Please select atleast one",
};

export const ApiPaths = {
    User: {
        login: `${CommonConstant.BASE_URL}account/login`,
        list: `${CommonConstant.BASE_URL}user/getallusers`,
        create: `${CommonConstant.BASE_URL}user/upsert`,
        edit: `${CommonConstant.BASE_URL}user/upsert`,
        getUserDetailsById: `${CommonConstant.BASE_URL}user/get`,
        delete: `${CommonConstant.BASE_URL}user/delete`,
        forgotPassword: `${CommonConstant.BASE_URL}account/forgotpassword`,
        resetPassword: `${CommonConstant.BASE_URL}account/resetpassword`,
        getPrivilege: `${CommonConstant.BASE_URL}user/GetUserModulePrivilages`,
        setPrivilege: `${CommonConstant.BASE_URL}user/AddUserModulePrivilage`,
        getUsersByFilter: `${CommonConstant.BASE_URL}user/GetUsersByFilter`,
        getAllReportingUser: `${CommonConstant.BASE_URL}user/getAllReportingUser`,
        validToken: `${CommonConstant.BASE_URL}User/ValidateToken`,
        downloadUserList: `${CommonConstant.BASE_URL}User/dowloadreport`,
        unlock: `${CommonConstant.BASE_URL}user/unlock`,
    },
    File: {
        uploadFile: `${CommonConstant.BASE_URL}api/v1/file/upload`,
        removeFile: `${CommonConstant.BASE_URL}api/v1/file/removeFile`,
        getDetailsById: `${CommonConstant.BASE_URL}api/v1/file/getDetailsById`,
        downloadLink: `${CommonConstant.BASE_URL}api/v1/file/download?id=`,
    },
    Report: {
        list: `${CommonConstant.BASE_URL}api/v1/report/get`,
        getDetailsByBriefId: `${CommonConstant.BASE_URL}account/report-detail/id/`,
        download: `${CommonConstant.BASE_URL}api/v1/report/dowloadreport`,
    },
    Brief: {
        dashboard: `${CommonConstant.BASE_URL}brief/getbriefbyfilter`,
        campaignDropDownList: `${CommonConstant.BASE_URL}brief/getcampaigndropdownlist`,
        objectives: `${CommonConstant.BASE_URL}master/getplatformobjective`,
        setBrief: `${CommonConstant.BASE_URL}brief/SaveBrief`,
        updateBriefStatus: `${CommonConstant.BASE_URL}brief/UpdateStatus`,
        updateSpentDetails: `${CommonConstant.BASE_URL}brief/updateSpentDetails`,
        getSpentDetailsHistory: `${CommonConstant.BASE_URL}brief/getSpentDetailsHistory`,
        getReportingTabHistory: `${CommonConstant.BASE_URL}brief/getReportingTabHistory`,
        getBrief: `${CommonConstant.BASE_URL}brief/Get`,
        getBriefFilterDdl: `${CommonConstant.BASE_URL}brief/GetBriefFilterDdl`,
        getHistoryIO: `${CommonConstant.BASE_URL}api/v1/io/getHistory`,
        getThirdPartyHistoryIO: `${CommonConstant.BASE_URL}api/v1/io/getThirdPartyHistory`,
        postIO: `${CommonConstant.BASE_URL}api/v1/io/postio`,
        postThirdPartyIo: `${CommonConstant.BASE_URL}api/v1/io/postThirdPartyIo`,
        getHistoryPO: `${CommonConstant.BASE_URL}api/v1/po/gethistorypo`,
        getPOCampaignHistory: `${CommonConstant.BASE_URL}api/v1/po/getPOCampaignHistory`,
        postPO: `${CommonConstant.BASE_URL}api/v1/po/postpo`,
        getHistoryDA: `${CommonConstant.BASE_URL}api/v1/digitalasset/GetHistoryDigitalAsset`,
        postDA: `${CommonConstant.BASE_URL}api/v1/digitalasset/PostDigitalAsset`,
        getHistoryIMP: `${CommonConstant.BASE_URL}api/v1/ImplementationPlan/GetHistoryImplementationPlan`,
        postIMP: `${CommonConstant.BASE_URL}api/v1/ImplementationPlan/PostImplementationPlan`,
        getHistoryCampaignStatus: `${CommonConstant.BASE_URL}api/v1/funding/GetHistory`, //`${CommonConstant.BASE_URL}api/v1/CampaignStatus/getHistoryCampaignStatus`,
        postCampaignStatus: `${CommonConstant.BASE_URL}api/v1/CampaignStatus/PostCampaignStatus`,
        getHistoryAcknowldgeLive: `${CommonConstant.BASE_URL}api/v1/funding/GetHistory`, // `${CommonConstant.BASE_URL}api/v1/AcknowldgeLive/getHistoryAcknowldgeLive`,
        postAcknowldgeLive: `${CommonConstant.BASE_URL}api/v1/funding/GetHistory`, //`${CommonConstant.BASE_URL}api/v1/AcknowldgeLive/postAcknowldgeLive`,
        getHistoryFundingCode: `${CommonConstant.BASE_URL}api/v1/FundingCode/getHistoryFundingCode`,
        postFundingCode: `${CommonConstant.BASE_URL}api/v1/FundingCode/postFundingCode`,
        getHistoryCampaignTracker: `${CommonConstant.BASE_URL}api/v1/CampaignTracker/getHistoryCampaignTracker`,
        postCampaignTracker: `${CommonConstant.BASE_URL}api/v1/CampaignTracker/postCampaignTracker`,
        getHistoryAssignOrValidation: `${CommonConstant.BASE_URL}brief/validation`,
        addReportingTabDetails: `${CommonConstant.BASE_URL}brief/addReportingTabDetails`,
        postPoAmountAlert: `${CommonConstant.BASE_URL}brief/postPoAmountAlert`,
    },
    Funding: {
        generateFundingCode: `${CommonConstant.BASE_URL}api/v1/funding/GenerateFundingCode`,
        campaignDropDownList: `${CommonConstant.BASE_URL}api/v1/funding/GetCampaignList`,
        getHistory: `${CommonConstant.BASE_URL}api/v1/funding/GetHistory`,
        saveFunding: `${CommonConstant.BASE_URL}api/v1/funding/Post`,
    },
    Campaign: {
        getCampaignDetailsById: `${CommonConstant.BASE_URL}api/v1/campaign/GetCampaignDetailsById`,
        getCampaignPlanDetails: `${CommonConstant.BASE_URL}api/v1/campaign/GetCampaignPlanDetails`,
        postCampaign: `${CommonConstant.BASE_URL}api/v1/campaign/UpdateCampaignPlan`,
        campaignPlanHistoryList: `${CommonConstant.BASE_URL}api/v1/campaign/GetHistoryCampaignPlan`,
        campaignPlanList: `${CommonConstant.BASE_URL}api/v1/campaign/GetCampaignPlanList`,
        postAssignOrValidation: `${CommonConstant.BASE_URL}api/v1/campaign/AddOrUpdateCampaignAssign`,
        getCampaignAssignHistoryList: `${CommonConstant.BASE_URL}api/v1/campaign/GetCampaignAssignHistory`,
        getHistoryApproveorReject: `${CommonConstant.BASE_URL}api/v1/campaign/GetCampaignApproveRejectHistory`, // `${CommonConstant.BASE_URL}api/v1/campaign/GetApproveorRejecHistory`,
        postApproveorReject: `${CommonConstant.BASE_URL}api/v1/campaign/postApproveorReject`,
        postCampaignSetup: `${CommonConstant.BASE_URL}api/v1/campaign/PostCampaignSetup`,
        postSpendProofLive: `${CommonConstant.BASE_URL}api/v1/CampaignLiveProof/post`,
        getCampaignSetupHistoryList: `${CommonConstant.BASE_URL}api/v1/campaign/GetCampaignSetupHistory`,
        getSpendProofLiveHistoryList: `${CommonConstant.BASE_URL}api/v1/CampaignLiveProof/GetHistory`,
        postSendReminder: `${CommonConstant.BASE_URL}api/v1/campaign/SendCampaignSetupReminder`,
        setvalidateNexusNumber: `${CommonConstant.BASE_URL}api/v1/campaign/ValidateNexusNumber`,
        postActuals: `${CommonConstant.BASE_URL}api/v1/campaign/PostActuals`,
    },
    ImplementationPlan: {
        create: `${CommonConstant.BASE_URL}api/v1/ImplementationPlan/PostImplementationPlan`,
        getHistory: `${CommonConstant.BASE_URL}api/v1/ImplementationPlan/GetHistoryImplementationPlan`,
        getPlanItemHistory: `${CommonConstant.BASE_URL}api/v1/ImplementationPlan/GetHistoryImplementationPlanItem`,
        createThirdPartyIm: `${CommonConstant.BASE_URL}api/v1/ImplementationPlan/postThirdPartyIm`,
        createPlanItem: `${CommonConstant.BASE_URL}api/v1/ImplementationPlan/PostImplementationPlanItem`,
    },
    Master: {
        getBrand: `${CommonConstant.BASE_URL}master/getbrand`,
        getClient: `${CommonConstant.BASE_URL}master/getclient`,
        getEvent: `${CommonConstant.BASE_URL}Master/GetEvents`,
        getStatus: `${CommonConstant.BASE_URL}Master/GetStatus`,
        getPlatform: `${CommonConstant.BASE_URL}Master/GetPlatform`,
        getDepartment: `${CommonConstant.BASE_URL}master/getDepartment`,
        getRoleTypes: `${CommonConstant.BASE_URL}master/GetRole`,
        getAllAgency: `${CommonConstant.BASE_URL}master/GetAgency`,
        getAgencyByRole: `${CommonConstant.BASE_URL}master/GetAgencyByRole`,
        getAssignedto: `${CommonConstant.BASE_URL}master/getassignedto`,
        getAllPlatforms: `${CommonConstant.BASE_URL}master/GetAllPlatforms`,
        getAllClientsByAgency: `${CommonConstant.BASE_URL}master/GetClientByAgency`,
        getAllBrandsByFilter: `${CommonConstant.BASE_URL}master/GetAllBrandsByFilter`,
        getPlatformById: `${CommonConstant.BASE_URL}master/getPlatformById`,
        getAllObjectiveByPlatform: `${CommonConstant.BASE_URL}master/GetAllObjectiveByPlatform`,
        getAllUniqueObjectives: `${CommonConstant.BASE_URL}master/GetAllUniqueObjectives`,
        saveObjective: `${CommonConstant.BASE_URL}master/SaveObjective`,
        savePlatfrom: `${CommonConstant.BASE_URL}master/SavePlatfrom`,
        saveClient: `${CommonConstant.BASE_URL}master/SaveClient`,
        saveBrand: `${CommonConstant.BASE_URL}master/SaveBrand`,
        getAllCurrencies: `${CommonConstant.BASE_URL}master/GetAllCurrencies`,
    },

    Notification: {
        getAll: `${CommonConstant.BASE_URL}api/v1/notification/getAll`,
        setRead: `${CommonConstant.BASE_URL}api/v1/notification/UpdateReadStatus`,
    },
    Notes: {
        create: `${CommonConstant.BASE_URL}api/v1/Notes/PostNotes`,
        getHistory: `${CommonConstant.BASE_URL}api/v1/Notes/GetNotesHistory`,
    },
};

export const RouteConstant = {
    homePage: "/home",
    userListPage: "/user-list",
    userCreatePage: "/user-create",
    userEditPage: "/user-edit/:id",
    userPrivilegePage: "/user/privilege/:id",
    getUserPrivilegePageUrl: (userId: string) => {
        return `/user/privilege/${userId}?`;
    },
    userForgot: "/user-forgot",
    userReset: "/user-reset",
    reportListPage: "/report-list",
    reportDetailPage: "/report-detail/:id",
    getCreateBriefUrl: () => {
        return `/brief/create`;
    },
    getViewBriefUrl: (id: string | number) => {
        return `/brief/${PageAction.View}/${id}`;
    },
    getEditBriefUrl: (id: string | number) => {
        return `/brief/${PageAction.Edit}/${id}`;
    },
    getCloneBriefUrl: (id: string | number) => {
        return `/brief/${PageAction.Copy}/${id}`;
    },
    createBrief: "/brief/create",
    viewBrief: "/brief/:action/:id?",
    createFunding: "/create-funding",
    managePlatform: "/manage/platform",
    manageClient: "/manage/client",
    manageBrand: "/manage/brand",
    manageObjectives: "/manage/objective/platform/:id",
    getManageObjectivesUrl: (id?: number) => {
        return `/manage/objective/platform/${id}`;
    },
    moreInfo: "/more-info/:status?/:id?/:notificationId?",
    getMoreInfoUrl: (status: string | number, id: number) => {
        return `/more-info/${status}/${id}`;
    },
    base: "/",
    login: "/login",
    campaignListPage: "/campaign/plan-list",
    campaignPlanPage: "/campaign/plan-details/:id",
    getCampaignPlanpageUrl: (id: number) => {
        return `/campaign/plan-details/${id}`;
    },
};
