import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import FormFeedback from "reactstrap/lib/FormFeedback";
import { formatCurrency } from "utility";
import { FieldColumnLayout, getRequiredLabel } from "./common";
import { ICustomInputProps, ILayoutProps } from "./types";
import { getErrorAndValue } from "./utils";
// import Cleave from "cleave.js/react";

interface ITxtProps extends ILayoutProps {
    placeholder?: string;
    value?: string | number;
}

export function ReadOnlyTextInput({ sm, md, lg, value, noColumn, placeholder, ...props }: ITxtProps) {
    return (
        <FieldColumnLayout label={props.label} sm={sm} md={md} lg={lg} noColumn={noColumn}>
            <Input placeholder={placeholder} value={value ?? ""} disabled />
        </FieldColumnLayout>
    );
}

export function CustomTextInput<T>({ sm, md, lg, noColumn, toolTip, labelRenderor, ...props }: ICustomInputProps<T>) {
    let label = labelRenderor ? labelRenderor() : getRequiredLabel(props.required, props.label, props.disabled ? null : toolTip);
    return (
        <FieldColumnLayout label={label} sm={sm} md={md} lg={lg} noColumn={noColumn}>
            <TextInput {...props} />
        </FieldColumnLayout>
    );
}
// const numberOptions = {
//     numeral: true,
//     numeralThousandsGroupStyle: "thousand",
// };
export function TextInput<T>({
    id,
    label,
    isMultiline,
    toUpper,
    form,
    placeholder,
    numberOnly,
    viewAsText,
    currencySymbol,
    noColumn,
    ...rest
}: ICustomInputProps<T>) {
    toUpper = toUpper === undefined ? true : toUpper;
    let { getFieldProps } = form;
    // if (placeholder === undefined) {
    //     placeholder = `Enter ${label}`;
    // }
    let { touchValue, errorValue, selectedValue } = getErrorAndValue(id, form);
    const inputProps = getFieldProps(id);
    const canShowError = (touchValue || form.isSubmitting) && errorValue;
    let inputType: any = isMultiline ? "textarea" : "text";
    if ((numberOnly || currencySymbol) && !rest.disabled) {
        inputType = "number";
    }
    let value = inputProps.value;
    if (rest.disabled && currencySymbol && value !== undefined) {
        value = formatCurrency(Number(value));
    }
    let getInput = (className: string) => (
        <Input
            title={rest.disabled ? value ?? "" : ""}
            type={inputType}
            id={id}
            className={className || ""}
            placeholder={placeholder}
            {...getFieldProps(id)}
            value={value ?? ""}
            onChange={(e) => {
                if (rest.disabled) return;
                let val = e.target.value;
                form.setFieldValue(id, val);
            }}
            onBlur={(e) => {
                if (rest.disabled) return;
                let val = Number(e.target.value.trim());
                if (val && currencySymbol && selectedValue !== Number(val)) {
                    form.setFieldValue(id, val.toFixed(2));
                } else if (e.target.value.length !== e.target.value.trim().length) {
                    form.setFieldValue(id, e.target.value.trim());
                }
                rest.onFocus?.();
            }}
            {...rest}
            min={rest.min || 0}
        />
    );
    const getInputGroupClass = () => {
        const cls = ["input-group-merge"];
        if (canShowError) {
            cls.push("is-invalid");
        }
        if (rest.disabled) {
            cls.push("disabled");
        }
        return cls.join(" ");
    };
    return (
        <>
            {viewAsText ? (
                <>{selectedValue || ""}</>
            ) : (
                <>
                    {currencySymbol ? (
                        <InputGroup className={getInputGroupClass()}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{currencySymbol}</InputGroupText>
                            </InputGroupAddon>
                            {getInput("")}
                        </InputGroup>
                    ) : (
                        getInput(canShowError ? "is-invalid" : "")
                    )}

                    {canShowError && <FormFeedback>{errorValue}</FormFeedback>}
                </>
            )}
        </>
    );
}
