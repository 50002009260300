import { IFileDetails } from "services/file/file-service";
import { Formik } from "./types";
import moment from "moment";

export function resolveValue(path: any, obj: any, separator = ".") {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
}
export const getJson = (text: string) => {
    try {
        return JSON.parse(text);
    } catch {
        return null;
    }
};
export function getErrorAndValue(id: string, form: Formik<any>) {
    let { values, errors, touched } = form;
    let ids = id.split(".");
    let selectedValue = ids.length > 0 ? resolveValue(ids, values) : values[id];
    let errorValue = ids.length > 0 ? resolveValue(ids, errors) : errors[id];
    let touchValue = ids.length > 0 ? resolveValue(ids, touched) : touched[id];
    return { selectedValue, touchValue, errorValue };
}

export function addOption<T>(value?: T, label?: string, key?: string) {
    return value !== undefined && value !== null && label ? { label: label, value: value, key } : undefined;
}

export function getDate(startDate?: Date | string, endDate?: Date | string) {
    return startDate && endDate ? { start: moment(startDate).toDate(), end: moment(endDate).toDate() } : undefined;
}

export function getClassNames(obj: Record<string, any>) {
    const clas: string[] = [];
    Object.keys(obj).forEach((k) => {
        if (obj[k]) {
            clas.push(k);
        }
    });
    return clas.join(" ");
}

export function addFile(idOrDetail?: string | IFileDetails | IFileDetails[], fileName?: string, fileSize?: number) {
    if (!idOrDetail) {
        return [];
    }
    if (typeof idOrDetail === "string") {
        let details: IFileDetails = {
            fileName: fileName,
            fileSize: fileSize,
        } as IFileDetails;
        details.id = idOrDetail;
        return [details];
    } else if (Array.isArray(idOrDetail)) {
        return idOrDetail;
    } else {
        return [idOrDetail];
    }
}
