import { ApiPaths } from "constants/common";
import { convertFromRaw, convertToRaw, EditorState, RawDraftContentState } from "draft-js";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Badge } from "reactstrap";
import FormFeedback from "reactstrap/lib/FormFeedback";
import { FileService } from "services/file/file-service";
import { errorToast } from "utility";
import { FieldColumnLayout, getRequiredLabel } from "./common";
import { CustomTextInput } from "./custom-input";
import { addMetaData } from "./custom-upload";
import { GroupNameType, ICustomEditorProps, IFileMetaData, IUploadedFileObject } from "./types";
import { getErrorAndValue, getJson } from "./utils";

interface IWithProps extends Omit<ICustomEditorProps<any>, "form"> {}
export function CustomEditorInputWithForm(props: IWithProps) {
    const form = useFormik({
        onSubmit: () => {},
        initialValues: {
            [props.id]: props.value,
        },
    });
    return <CustomEditorInput {...props} form={form} />;
}

export function CustomEditorInput<T>(props: ICustomEditorProps<T>) {
    const { sm, md, lg, noColumn, toolTip, ...rest } = props;
    const inputProps = rest.form.getFieldProps(rest.id);
    const val = inputProps.value;
    const json = getJson(val);
    const isJson = !!(val && json);
    const [showEditor, setShowEditor] = useState(isJson);

    useEffect(() => {
        if (isJson) {
            if (!showEditor) {
                setShowEditor(true);
            }
        }
    }, [isJson, showEditor]);
    const view = rest.disabled ? null : (
        <>
            <Badge
                className="text-capitalize cursor-pointer"
                color={undefined}
                pill
                onClick={() => {
                    setShowEditor((t) => {
                        const nextSt = !t;
                        if (nextSt === false) {
                            if (json) {
                                const dd: RawDraftContentState = json;
                                rest.form.setFieldValue(rest.id, dd?.blocks?.map((a) => a.text)?.join(" "));
                            } else {
                                rest.form.setFieldValue(rest.id, val);
                            }
                        }
                        return nextSt;
                    });
                }}
            >
                {showEditor ? "Hide Editor" : "Show Editor"}
            </Badge>
        </>
    );
    let label = getRequiredLabel(rest.required, rest.label, toolTip, view);
    if (rest.hideToolbar) {
        return <EditorInput {...rest} />;
    }
    if (showEditor) {
        return (
            <FieldColumnLayout label={label} sm={sm} md={md} lg={lg} noColumn={noColumn}>
                <EditorInput {...rest} />
            </FieldColumnLayout>
        );
    }
    return <CustomTextInput {...props} isMultiline labelRenderor={() => label} />;
}
// const numberOptions = {
//     numeral: true,
//     numeralThousandsGroupStyle: "thousand",
// };

export const convertToEditorState = (raw: string): EditorState | undefined => {
    if (raw) {
        try {
            const dt: RawDraftContentState = JSON.parse(raw);
            return EditorState.createWithContent(convertFromRaw(dt));
        } catch {
            return EditorState.createWithContent(
                convertFromRaw({
                    blocks: [{ key: "5s34f", text: raw, type: "unstyled", depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }],
                    entityMap: {},
                })
            );
        }
    }
    return undefined;
};
export function EditorInput<T>({ id, label, form, placeholder, viewAsText, noColumn, ...rest }: ICustomEditorProps<T>) {
    const inputProps = form.getFieldProps(id);
    const [state, setState] = useState<EditorState>(EditorState.createEmpty());
    useEffect(() => {
        const editorValu = convertToEditorState(inputProps.value);
        setState(editorValu ?? EditorState.createEmpty());
    }, [inputProps.value, rest.value]);
    let { touchValue, errorValue } = getErrorAndValue(id, form);
    const canShowError = (touchValue || form.isSubmitting) && errorValue;
    const uploadImageCallBack = (file: File) => {
        return new Promise((resolve, reject) => {
            let postdata = new FormData();

            const fileMetaData: IFileMetaData = {
                fileGroup: GroupNameType.InLineImage,
            };
            postdata.append("files", file);
            addMetaData(fileMetaData, postdata);

            const setError = () => {
                const error = "Error while uploading. Please try again later";
                errorToast(error);
                reject(error);
            };
            FileService.upload(postdata)
                .then((res) => {
                    if (res.data.success) {
                        let data = res.data.data;
                        if (Array.isArray(data)) {
                            let fData = data;
                            const ext: keyof IUploadedFileObject = "uploadedFileList";
                            form.setValues((fstate: any) => ({
                                ...fstate,
                                [ext]: [...(fstate[ext] ?? []), ...fData],
                            }));
                            const path = `${window.location.origin}${ApiPaths.File.downloadLink}${data[0].id}`;
                            resolve({ data: { link: path } });
                        }
                    } else {
                        setError();
                    }
                })
                .catch(() => {
                    setError();
                });
        });
    };
    const toolbar = {
        options: [
            "inline",
            "blockType",
            // "fontSize",
            // "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            // "embedded",
            "emoji",
            "image",
            "remove",
            "history",
        ],

        inline: { inDropdown: true },
        list: { inDropdown: false },
        textAlign: { inDropdown: false },
        link: { inDropdown: true },
        history: { inDropdown: true },
        image: {
            uploadEnabled: true,
            alignmentEnabled: true,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            uploadCallback: (file: File) => uploadImageCallBack(file),
            alt: { present: false, mandatory: false },
        },
    };
    let getInput = (className: string) => (
        <Editor
            editorState={state}
            onEditorStateChange={setState}
            wrapperClassName={`editor-wrapper ${className}`}
            readOnly={rest.disabled}
            editorClassName="editor-editor"
            onBlur={() => {
                const contentState = state.getCurrentContent();
                const raw = convertToRaw(contentState);
                const json = JSON.stringify(raw);
                const blocks: any = raw.blocks?.[0];
                const hasEntityMap = Object.keys(raw.entityMap).length > 0;
                if (!blocks || (!hasEntityMap && !blocks.text && blocks.type === "unstyled")) {
                    form.setFieldValue(id, undefined);
                } else if (json !== inputProps.value) {
                    form.setFieldValue(id, json);
                }
            }}
            toolbar={toolbar}
        />
    );
    return (
        <>
            {rest.disabled ? (
                <>
                    <Editor
                        editorState={state}
                        onEditorStateChange={setState}
                        wrapperClassName={`editor-wrapper ${rest.hideToolbar ? "noborder" : ""} ${rest.disabled ? "disabled" : ""}`}
                        toolbarClassName="disabled-toolbar"
                        readOnly={true}
                        toolbarHidden={rest.hideToolbar || rest.disabled}
                        editorClassName="editor-editor"
                        toolbar={toolbar}
                    />
                </>
            ) : (
                <>
                    {getInput(canShowError ? "is-invalid" : "")}

                    {canShowError && <FormFeedback>{errorValue}</FormFeedback>}
                </>
            )}
        </>
    );
}
