import { atom } from "recoil";
import { IPagedNotificationResponse } from "services/notification/types";

export const loaderState = atom<boolean>({
    key: "loaderState",
    default: false,
});

export const notificationState = atom<IPagedNotificationResponse | undefined>({
    key: "notificationState",
    default: undefined,
});

export const notificationUpdateState = atom<number>({
    key: "notificationUpdateState",
    default: 0,
});
