import { ApiPaths } from "constants/common";
import { Ajax, IApiResponse } from "utility/ajax";

export const FileService = {
    upload: function (payload: FormData) {
        return Ajax.postRequest<IApiResponse<IFileDetails | IFileDetails[]>>(ApiPaths.File.uploadFile, payload, true);
    },
    removeFile: function (id: string) {
        return Ajax.postRequest<IApiResponse<string>>(`${ApiPaths.File.removeFile}?id=${id}`, {});
    },
    getDetailsById: function (id: string) {
        return Ajax.postRequest<IApiResponse<IFileDetails>>(`${ApiPaths.File.getDetailsById}?id=${id}`, {});
    },
};
export interface IFileDetails {
    addedById: string;
    dateCreated: string;
    fileName: string;
    filePath: string;
    fileSize: number;
    fileType: string;
    id: string;
    isDeleted: boolean;
    isTemp: boolean;
    source: 0 | 1;

    fid?: string;
}
