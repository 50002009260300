import { IBriefFilterModel, IBriefModel } from "services/brief/types";
import { IBriefDetailsResponse, ICampaignAssignResponse, IDigitalAssetsResponse, PoRequiredType } from "services/brief/types.response";
import { ICampaignPlanItemResponse } from "services/campaign/types";
import { ICampaignSetupResponse } from "services/campaign/types.response";
import { FormListEnum } from "services/master/types.form";
import { Status } from "services/master/types.status";
import { IFilterData } from "./brief-filter";
import { IObjectiveCard, IObjectiveRow } from "./objective/types";

export enum PageAction {
    Edit = "edit",
    Copy = "copy",
    View = "view",
}

export const isNoPoRequired = (data: IBriefDetailsResponse) => {
    return data.contact?.poRequired === PoRequiredType.NoPORequired;
};
export const getBriefStatus = (row: IBriefModel) => {
    if ([Status.CampaignLive, Status.CampaignEnded, Status.Draft, Status.InActive].includes(row.statusId)) {
        return row.statusDescription;
    } else if (Status.Delayed === row.overallStatusId) {
        return "Delayed";
    }
    return "In Progress";
};

type BudgetKeys = keyof Pick<IObjectiveRow, "adServing" | "brandSafety" | "budget" | "platformAgencyFee" | "sa360">;
export function getSumOfObjectiveBudget(data: IObjectiveCard[], key: BudgetKeys = "budget") {
    let total = data
        .filter((fItem) => !fItem.isDeleted)
        .reduce((prv, item) => {
            return (
                prv +
                item.objectiveRows
                    .filter((item) => !item.isDeleted)
                    .reduce((p1, i1) => {
                        return p1 + Number(i1[key] || 0);
                    }, 0)
            );
        }, 0);
    return Number(total.toFixed(4));
}
export function toBriefFilter(filter: IFilterData, agencyId?: number): IBriefFilterModel | undefined {
    if (filter.isSearch) {
        if (filter.searchBy?.value && filter.searchByVal) {
            return {
                searchBy: filter.searchBy?.value,
                searchByVal: filter.searchByVal,
            };
        }
    } else {
        const fil: IBriefFilterModel = {
            agencyId: agencyId ?? filter.agency?.value ?? 0,
            brandId: filter.brand?.value ?? 0,
            clientId: filter.client?.value ?? 0,
            statusId: filter.status?.value ?? 0,
            progressId: filter.progress?.value ?? 0,
            platformId: filter.platform?.value ?? 0,
            campaignManagerId: filter.campaignManager?.value ? filter.campaignManager?.value : undefined,
            eventId: filter.event?.value ?? 0,
            startDate: filter.dateRange ? filter.dateRange.start : null,
            endDate: filter.dateRange ? filter.dateRange.end : null,
            strategistId: filter.Strategist?.value ? filter.Strategist?.value : undefined,
        };
        return fil;
    }
}
export const getCampaignPlan = (
    data: IBriefDetailsResponse,
    formId: FormListEnum,
    platformId?: number
): ICampaignPlanItemResponse | undefined => {
    let plans = data?.campaignDetail?.campaignPlan?.campaignPlanItems || [];
    let plan = plans.filter((a: any) => {
        return a.formListId === formId && (!platformId || a.platformId === platformId);
    })[0];
    return plan;
};

export const getSelectedBudgetType = (isMonthly: boolean) => {
    const val = isMonthly ? "Yes" : "No";
    return getBudgetTypeDdl().filter((a) => a.value === val)[0];
};

export const getBudgetTypeDdl = () => {
    return [
        {
            label: "The total campaign budget divided by the number of months.",
            value: "No",
        },
        {
            label: "Different budget per month ",
            value: "Yes",
        },
    ];
};

export function getClonedBriefDetails(data: IBriefDetailsResponse) {
    data = removeDeletedItemsFromBriefDetails(data);
    let val: IBriefDetailsResponse = {
        ...data,
        id: undefined as any,
        statusId: Status.Draft,
        contact: { ...data.contact!, id: undefined as any, fileUpload: undefined },
        status: {
            id: Status.Draft,
            name: "Draft",
        },
        campaignDetail: data.campaignDetail
            ? {
                  ...data.campaignDetail,
                  id: undefined as any,
                  campaignName: `${data.campaignDetail.campaignName} - copy`,
                  campaignMonthlyBugets: data.campaignDetail?.campaignMonthlyBugets?.map((a) => ({ ...a, id: undefined as any })),
              }
            : undefined,
        targettingInstruction: data.targettingInstruction?.map((a) => ({ ...a, id: undefined as any })),
        spentByObjectives: data.spentByObjectives?.map((a) => ({
            ...a,
            id: undefined as any,
            spentByObjectiveAndBudgets: a.spentByObjectiveAndBudgets.map((a) => ({ ...a, id: undefined as any })),
        })),
    };
    return val;
}

export function removeDeletedItemsFromBriefDetails(data: IBriefDetailsResponse) {
    let val: IBriefDetailsResponse = {
        ...data,
        campaignDetail: data.campaignDetail
            ? {
                  ...data.campaignDetail,
                  campaignMonthlyBugets: data.campaignDetail?.campaignMonthlyBugets?.filter((a) => !a.isDeleted),
              }
            : undefined,
        targettingInstruction: data.targettingInstruction?.filter((a) => !a.isDeleted),
        campaignAssigns: data.campaignAssigns?.filter((a) => !a.isDeleted),
        spentByObjectives: data.spentByObjectives
            ?.filter((a) => !a.isDeleted)
            .map((a) => ({
                ...a,
                spentByObjectiveAndBudgets: a.spentByObjectiveAndBudgets.filter((f) => !f.isDeleted),
            })),
        campaignSetups: data.campaignSetups?.filter((a) => !a.isDeleted),
    };
    return val;
}

export function getAssignedPlatfroms(
    platformId: number,
    campaignAssigns: ICampaignAssignResponse[],
    userId: string,
    isGlobalAdmin: boolean
) {
    return (campaignAssigns.filter((a) => a.platformId === platformId && (a.assignedUserId === userId || isGlobalAdmin)) ?? [])[0];
}

export function isAllCampaignAssigned(data: IBriefDetailsResponse) {
    let plCount = data.spentByObjectives?.length ?? 0;
    let assigned = data.spentByObjectives?.filter((o) => {
        let assignedUser = data.campaignAssigns?.filter(
            (a) =>
                a.assignedUserId &&
                o.platform.id === a.platformId &&
                (a?.statusId === Status.AssignToCampaignManager || a?.statusId === Status.Assign)
        )[0]?.assignedUserId;
        return !!assignedUser;
    });
    return plCount === assigned?.length;
}

export function isAllPlatformAssigned(data: IBriefDetailsResponse) {
    let assigned = data.spentByObjectives?.filter((o) => {
        let assignedUser = data.campaignAssigns?.filter((a) => a.assignedUser && o.platform.id === a.platformId)[0]?.assignedUser;
        return !!assignedUser;
    });
    return assigned?.length === data.spentByObjectives?.length;
}

export function getCompletedCampaignSetup(platformId: number, campaignSetups?: ICampaignSetupResponse[]) {
    let all = campaignSetups?.filter((a) => a.platformId === platformId && a.setupStatusId === Status.Approved) ?? [];
    return all;
}
export function getCompletedDigitalAssets(platformId: number, assets?: IDigitalAssetsResponse[]) {
    let all = assets?.filter((a) => a.platformId === platformId && a.statusId === Status.Approved) ?? [];
    return all;
}
export function isMyCampaignSetupCompleted(data: IBriefDetailsResponse, userId: string, isGlobalAdmin: boolean) {
    let myCount = data.campaignAssigns?.filter((a) => a.assignedUserId === userId || isGlobalAdmin).map((p) => p.platformId);
    return (
        (myCount?.length ?? 0) ===
        data.campaignSetups?.filter(
            (a) => [Status.Approved, Status.SubmitForReviewInternally].includes(a.setupStatusId!) && myCount?.includes(a.platformId)
        ).length
    );
}
export function isAllCampaignSetupCompleted(data: IBriefDetailsResponse) {
    let plCount = data.spentByObjectives?.length ?? 0;
    return (
        plCount ===
        data.campaignSetups?.filter((a) => [Status.Approved, Status.SubmitForReviewInternally].includes(a.setupStatusId!)).length
    );
}

export function getAnyCompletedCampaignSetup(data: IBriefDetailsResponse) {
    return data.campaignSetups?.filter((a) => a.setupStatusId === Status.Approved);
}

export function isCampaignSetupSendToAgencyCompleted(data: IBriefDetailsResponse, platformId: number) {
    return (
        (data.campaignSetups?.filter(
            (a) =>
                a.platformId === platformId &&
                a.agencyApprovalStatusId &&
                [Status.Approved, Status.SentToAgencyForApproval].includes(a.agencyApprovalStatusId)
        ).length ?? 0) > 0
    );
}
export function getApprovedOrRejectedCampaignByAgency(data: IBriefDetailsResponse, platformId: number) {
    return (
        data.campaignSetups?.filter(
            (a) =>
                a.platformId === platformId &&
                a.agencyApprovalStatusId &&
                [Status.Approved, Status.Rejected].includes(a.agencyApprovalStatusId)
        ) ?? []
    );
}
export function getAllApprovedCampaignByAgency(data: IBriefDetailsResponse, platformId?: number) {
    return (
        data.campaignSetups?.filter(
            (a) =>
                a.agencyApprovalStatusId &&
                (a.platformId === platformId || !platformId) &&
                [Status.Approved].includes(a.agencyApprovalStatusId)
        ) ?? []
    );
}
export function isAllCampaignStatusCompletedByAgency(data: IBriefDetailsResponse) {
    let plCount = data.spentByObjectives?.length ?? 0;
    return (
        plCount ===
        data.campaignSetups?.filter((a) => a.agencyApprovalStatusId && [Status.Approved].includes(a.agencyApprovalStatusId)).length
    );
}
export function isAllCampaignSetupSendToAgencyCompleted(data: IBriefDetailsResponse) {
    let plCount = data.spentByObjectives?.length ?? 0;
    return (
        plCount ===
        data.campaignSetups?.filter((a) => a.agencyApprovalStatusId && [Status.Approved].includes(a.agencyApprovalStatusId)).length
    );
}

export function getAllCampaignsForReview(data?: IBriefDetailsResponse) {
    return (
        data?.campaignSetups?.filter((campaignSetups) => {
            return [Status.SubmitForReviewInternally, Status.Approved, Status.Rejected].includes(campaignSetups?.setupStatusId!);
        }) ?? []
    );
}

export function isCampaignReviewCompleted(data?: IBriefDetailsResponse) {
    return (
        data?.campaignSetups &&
        data.campaignSetups.length > 0 &&
        data?.campaignSetups.every((a) => a.setupStatusId && [Status.Approved, Status.Rejected].includes(a.setupStatusId))
    );
}
export const isBriefCompleted = (data: IBriefDetailsResponse) => {
    return (
        (data?.status?.id === Status.CampaignLive || data?.status?.id === Status.CampaignEnded) && data?.formListId === FormListEnum.Brief
    );
};

export const isBriefCreatedBefore20230214 = (data: IBriefDetailsResponse) => {
    return data.dateCreated && new Date(data.dateCreated).getTime() < new Date(2023, 1, 14).getTime();
};
export const isDAEmpty = (data: IBriefDetailsResponse) => {
    if (data?.digitalAssets?.length === 0 || undefined) {
        return true;
    } else return false;
};
export const isPOEmpty = (data: IBriefDetailsResponse) => {
    if (!data?.purchaseOrders || undefined) {
        return true;
    } else return false;
};
export const isIMEmpty = (data: IBriefDetailsResponse) => {
    if (!data?.implementationPlans || undefined) {
        return true;
    } else return false;
};
export const isCampSetupEmpty = (data: IBriefDetailsResponse) => {
    if (data?.campaignSetups?.length === 0 || undefined) {
        return true;
    } else return false;
};
export const isPOLEmpty = (data: IBriefDetailsResponse) => {
    if (data?.campaignLiveProof?.length === 0 || undefined) {
        return true;
    } else return false;
};
export const isDigitalAssetCompleted = (data: IBriefDetailsResponse) => {
    if (isBriefCreatedBefore20230214(data)) {
        const id = data.digitalAssets?.[0]?.statusId;
        if (id) {
            return [Status.Approved, Status.Completed].includes(id);
        }
        return false;
    }
    return data.spentByObjectives?.every((o) =>
        data.digitalAssets?.some(
            (a) => o.platform.id === o.platform.id && a.statusId && [Status.Approved, Status.Completed].includes(a.statusId)
        )
    );
};
export function isAllLiveProofApprovedOrCompleted(data: IBriefDetailsResponse) {
    let plCount = data.spentByObjectives?.length ?? 0;
    return plCount === data.campaignLiveProof?.filter((a) => a.statusId && [Status.Approved, Status.Completed].includes(a.statusId)).length;
}
export const isLiveProofUploaded = (data: IBriefDetailsResponse, platformId: number, isCapture?: boolean) => {
    let status = [Status.Approved, Status.Completed, Status.LiveApprovalProof];
    if (isCapture) {
        status.push(Status.CaptureLiveProof);
    }
    return (
        (data.campaignLiveProof?.filter((a) => a.statusId && a.platformId === platformId && status.includes(a.statusId)).length ?? 0) > 0
    );
};

export const isImplementationPlanCompleted = (data: IBriefDetailsResponse) => {
    return data.implementationPlans?.statusId === Status.Completed || data.implementationPlans?.statusId === Status.Approved;
    // return (
    //     (data.implementationPlans?.implementationPlanItems.filter(
    //         (a) => a.statusId && [Status.Approved, Status.Completed].includes(a.statusId)
    //     ).length ?? 0) > 0
    // );
};
export const anyLiveProofApproved = (data: IBriefDetailsResponse) => {
    return data.campaignSetups?.some((c) => c.platformId && isLiveProofApproved(data, c.platformId));
};
export const isLiveProofApproved = (data: IBriefDetailsResponse, platformId: number) => {
    return (
        (data.campaignLiveProof?.filter(
            (a) => a.statusId && a.platformId === platformId && [Status.Approved, Status.Completed].includes(a.statusId)
        ).length ?? 0) > 0
    );
};
export function isAllLiveProofSentOrApproved(data: IBriefDetailsResponse) {
    let plCount = data.spentByObjectives?.length ?? 0;
    return plCount === data.campaignLiveProof?.filter((a) => a.statusId && [Status.Approved, Status.Completed].includes(a.statusId)).length;
}
export function isNewPOL(data: IBriefDetailsResponse) {
    let setupCount =
        (data?.campaignSetups?.filter((a) => a.statusId && [Status.Approved, Status.Completed].includes(a.statusId)).length ?? 0) > 0;
    if (setupCount) {
        setupCount = false; //checkinig status for 'tab color'
    } else {
        setupCount = true;
    }
    return setupCount;
}
export const isImplPlanItemApproved = (data: IBriefDetailsResponse, platformId: number) => {
    return (
        (data.implementationPlans?.implementationPlanItems?.filter(
            (a) => a.statusId && a.platformId === platformId && [Status.Approved, Status.Completed].includes(a.statusId)
        ).length ?? 0) > 0
    );
};
export const isImplPlanUploaded = (data: IBriefDetailsResponse, platformId: number) => {
    return (
        (data.implementationPlans?.implementationPlanItems?.filter(
            (a) => a.statusId && a.platformId === platformId && [Status.Uploaded, !Status.Rejected].includes(a.statusId)
        ).length ?? 0) > 0
    );
};
