import moment from "moment";
import { ReactElement } from "react";
import { toast } from "react-toastify";
const toastId: string = "errorToast";
export const errorToast = (msg: string | ReactElement) => {
    toast.error(msg, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 4000, toastId: toastId });
};

export const successToast = (msg: string) => {
    toast.success(msg, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000 });
};

export function roundOf(val: any) {
    return val && !isNaN(Number(val)) ? Math.round(Number(val)) : val;
}
export const addBusinessDays = (date: Date, days: number) => {
    var d = moment(new Date(date)).add(Math.floor(days / 5) * 7, "d");
    var remaining = days % 5;
    while (remaining) {
        d.add(1, "d");
        if (d.day() !== 0 && d.day() !== 6) remaining--;
    }
    return d;
};
export function dateDiff(startDate: string | Date, endDate: string | Date, as: "months" | "days") {
    var duration = moment.duration(moment(endDate).diff(moment(startDate)));
    if (as === "months") {
        return duration.asMonths();
    } else if (as === "days") {
        return duration.asDays();
    } else return 0;
}

export const formatCurrency = (amount: number | undefined) => {
    if (!amount && amount !== 0) return "";
    return parseFloat(Number(amount).toFixed(2)).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const getKeys = <T>(val: T) => {
    return (val ? Object.keys(val as any) : []) as Array<keyof T>;
};

export enum StorageKey {
    User = "userData",
}
const storageVersion = 1;
export function removeStorage(key: StorageKey) {
    localStorage.removeItem(key + storageVersion);
}
export function getStorageValue<T extends object | undefined>(key: StorageKey, defaultValue?: T): T extends object ? T : undefined {
    let val = localStorage.getItem(key + storageVersion);
    if (val) {
        try {
            let json = JSON.parse(val);
            return json;
        } catch (e) {
            console.log("Error while reading data");
            return defaultValue as any;
        }
    }
    return defaultValue as any;
}

export function setStorageValue<T>(key: StorageKey, value: T) {
    localStorage.setItem(key + storageVersion, JSON.stringify(value));
}
