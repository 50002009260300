import { Col, Row, Spinner } from "reactstrap";
import { useRecoilValue } from "recoil";
import { loaderState } from "store/common";

export const CustomLoader = () => {
    const isLoading = useRecoilValue(loaderState);
    return (
        <>
            {isLoading && (
                <div className={"fullpage-loader-con"}>
                    <div className="d-flex justify-content-center my-1 loading">
                        <Spinner />
                    </div>
                </div>
            )}
        </>
    );
};

export const CustomSpinner = () => {
    return (
        <Row>
            <Col className="d-flex justify-content-center">
                <Spinner className="text-center" />
            </Col>
        </Row>
    );
};
