import { AxiosResponse } from "axios";
import { FieldHelperProps, FieldInputProps, FieldMetaProps, FormikErrors, FormikState, FormikTouched } from "formik";
import React from "react";
import { ColumnProps } from "reactstrap/es/Col";
import { IFileDetails } from "services/file/file-service";
import { IApiResponse } from "utility/ajax";

export interface Formik<Values> {
    initialValues: Values;
    initialErrors: FormikErrors<unknown>;
    initialTouched: FormikTouched<unknown>;
    initialStatus: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    handleReset: (e: any) => void;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void;
    setErrors: (errors: FormikErrors<Values>) => void;
    setFormikState: (stateOrCb: FormikState<Values> | ((state: FormikState<Values>) => FormikState<Values>)) => void;
    setFieldTouched: (
        field: string,
        touched?: boolean,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<Values>> | Promise<void>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<Values>> | Promise<void>;
    setFieldError: (field: string, value: string | undefined) => void;
    setStatus: (status: any) => void;
    setSubmitting: (isSubmitting: boolean) => void;
    setTouched: (touched: FormikTouched<Values>, shouldValidate?: boolean | undefined) => Promise<FormikErrors<Values>> | Promise<void>;
    setValues: (
        values: React.SetStateAction<Values>,
        shouldValidate?: boolean | undefined
    ) => Promise<FormikErrors<Values>> | Promise<void>;
    submitForm: () => Promise<any>;
    validateForm: (values?: Values) => Promise<FormikErrors<Values>>;
    validateField: (name: string) => Promise<void> | Promise<string | undefined>;
    isValid: boolean;
    dirty: boolean;
    unregisterField: (name: string) => void;
    registerField: (name: string, { validate }: any) => void;
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    validateOnBlur: boolean;
    validateOnChange: boolean;
    validateOnMount: boolean;
    values: Values;
    errors: FormikErrors<Values>;
    touched: FormikTouched<Values>;
    isSubmitting: boolean;
    isValidating: boolean;
    status?: any;
    submitCount: number;
}

export interface ICustomCommonInputProps<T> {
    id: Exclude<keyof T, number | symbol> | string; // `${Exclude<keyof T, number | symbol>}.`;
    form: Formik<T>;
    value?: any;
    label?: React.ReactNode | string;
    labelRenderor?: () => React.ReactNode;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    viewAsText?: boolean;
    toolTip?: React.ReactNode | string;
    style?: React.CSSProperties;
}
export interface ILayoutProps {
    noColumn?: boolean;
    label?: React.ReactNode | string;
    className?: string;
    children?: React.ReactNode;
    xs?: ColumnProps;
    sm?: ColumnProps;
    md?: ColumnProps;
    lg?: ColumnProps;
    xl?: ColumnProps;
}

export interface ICustomInputProps<T> extends ICustomCommonInputProps<T>, ILayoutProps {
    isMultiline?: boolean;
    currencySymbol?: string;
    type?: string;
    toUpper?: boolean;
    numberOnly?: boolean;
    min?: number;
    max?: number;
    onFocus?: () => void;
    onBlur?: () => void;
}
export interface ICustomEditorProps<T> extends ICustomCommonInputProps<T>, ILayoutProps {
    hideToolbar?: boolean;
}

export interface ICustomCheckbox<T> extends ICustomCommonInputProps<T>, ILayoutProps {
    resetControlsOnchange?: string | string[];
    onChangeComplete?: (checked: boolean) => void;
}
export interface ICustomSlider<T> extends ICustomCommonInputProps<T>, ILayoutProps {
    range: {
        min: number;
        max: number;
    };
    defaultValue: string;
    isViewOnly?: boolean;
}

export interface ICustomRadio<T> extends ICustomCommonInputProps<T>, ILayoutProps {
    radioLabels: {
        label: string;
        value: string | boolean | number;
    }[];
}

export type DropDownOptionType = IDropdownOptions | IDropdownNumberOptions;
export interface ICustomDropdown<T, F, O extends DropDownOptionType = DropDownOptionType> extends ICustomCommonInputProps<T>, ILayoutProps {
    options?: O[];
    url?: string;
    resetControlsOnchange?: string | string[];
    optionRenderor?: (option: O) => React.ReactNode | string;
    onChange?: (item: O, id: string) => void;
    filter?: F;
    dataSource?: (filter?: F) => Promise<AxiosResponse<IApiResponse<O[]>>>;
    fixedOption?: O[];
    selectLabel?: string;
    hideSelect?: boolean;
    onDataReceived?: Function;
    isMulti?: boolean;
    showSelectAll?: boolean;
}

export interface IFileMetaData {
    fileGroup: GroupNameType;
}

export interface IUploadedFileObject {
    uploadedFileList: IFileDetails[];
}

export enum GroupNameType {
    "IO" = 0,
    "PO" = 1,
    "ImplPlan" = 2,
    "CampaignSetup" = 3,
    "SendLiveProof" = 4,
    "InLineImage" = 5,
    "Tagging" = 6,
    "Report" = 7,
}
export interface IFileUploader<T> extends ICustomCommonInputProps<T>, ILayoutProps {
    isMulti?: boolean;
    autoUpload?: boolean;
    fileMetaData?: IFileMetaData;
    allowedFiles?: AllowedFiles[];
    removeOnlyNew?: boolean;
    isViewOnly?: boolean;
}

export enum AllowedFiles {
    "PDF" = ".pdf",
    "HTML" = ".html",
    "HTM" = ".htm",
    "IMAGES" = "image/*",
    "XLS" = ".XLS",
    "XLSX" = ".XLSX",
    "EML" = ".eml",
}
export interface IDropdownOptions {
    label: string;
    value: string;
    key?: string;
}

export interface IDropdownOption<T> {
    label: string;
    value: T;
}
export interface IDropdownNumberOption<T = unknown> {
    label: string;
    value: number;
    data?: T;
}
export interface IDropdownNumberOptions {
    label: string;
    value: number;
    key?: string;
}
export interface ITableFilter<Filter> {
    page: number;
    pageSize: number;
    filter?: Filter;
}

export interface ISelectedDate {
    start: Date;
    end: Date;
}
