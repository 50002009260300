import "bootstrap/dist/css/bootstrap.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Suspense, lazy } from "react";

import { Provider } from "react-redux";
// ** Redux Imports
import { store } from "./redux/storeConfig/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

import "@core/scss/react/libs/tables/react-dataTable-component.scss";
import "bootstrap-daterangepicker/daterangepicker.css";
// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@core/scss/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "@fake-db/index";
import { CustomLoader } from "@core/components/custom/custom-loader";
import { RecoilRoot } from "recoil";
import { NoteModal } from "@core/components/custom/notes-popup";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || "";
const rootElement = document.getElementById("root");

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
    <RecoilRoot>
        <Provider store={store}>
            <Suspense fallback={<Spinner />}>
                <ThemeContext>
                    <BrowserRouter basename={baseUrl}>
                        <LazyApp />
                    </BrowserRouter>
                    <ToastContainer newestOnTop limit={2} />
                    <CustomLoader />
                    <NoteModal />
                </ThemeContext>
            </Suspense>
        </Provider>
    </RecoilRoot>,
    rootElement
);

// ReactDOM.render(
//     <>
//         <BrowserRouter basename={baseUrl}>
//             <App />
//         </BrowserRouter>
//     </>,
//     rootElement
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
