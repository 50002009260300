import { ReactNode, useRef, useState } from "react";
import { Col, FormGroup, Label, Tooltip } from "reactstrap";
import { ILayoutProps } from "./types";

export const RowLayout = ({ label, children, className }: ILayoutProps) => {
    return (
        <FormGroup row className={className}>
            <Label sm="3" for="name">
                {label}
            </Label>
            <Col sm="9">{children}</Col>
        </FormGroup>
    );
};

export const FieldColumnLayout = ({ label, children, className, noColumn, ...rest }: ILayoutProps) => {
    let content = (
        <FormGroup>
            {label ? <Label> {label} </Label> : ""}
            {children}
        </FormGroup>
    );
    return (
        <>
            {noColumn ? (
                content
            ) : (
                <Col sm={rest.sm || "4"} className={className} lg={rest.lg} md={rest.md}>
                    {content}
                </Col>
            )}
        </>
    );
};

export function getRequiredLabel(required?: boolean, label?: ReactNode, toolTip?: React.ReactNode | string, additional?: ReactNode) {
    const req = label && required ? <span style={{ color: "red" }}>*</span> : null;
    let labelText = (
        <>
            {label} {req} <RenderTooltip toolTip={toolTip} className="tooltip-icon" /> {additional}
        </>
    );
    return labelText;
}

interface IToolProps {
    toolTip: React.ReactNode | string;
    className?: string;
    triggerElement?: React.ReactNode | string;
}
export const RenderTooltip = ({ toolTip, className, triggerElement = "i" }: IToolProps) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const ref = useRef(null);
    if (!toolTip) return null;
    return (
        <>
            <span className={className} ref={ref}>
                {triggerElement}
            </span>
            <Tooltip
                isOpen={tooltipOpen}
                placement="right"
                target={ref}
                toggle={() => {
                    setTooltipOpen(!tooltipOpen);
                }}
            >
                {toolTip}
            </Tooltip>
        </>
    );
};

export const Heading = ({ title }: { title: string }) => {
    return (
        <Col sm="12">
            <h5 style={{ fontWeight: "bold", color: "#515255" }}>{title}</h5>
        </Col>
    );
};
