import { IFileDetails } from "services/file/file-service";
import { IFundingResponse } from "services/funding/types.response";
import { IImplementationPlanResponse } from "services/implementation/implementation-plan-service";
import { Status } from "services/master/types.status";
import { IHistoryResponse } from "services/types";
import { IUserDDL } from "services/user/types";
import { ICampaignSetupResponse } from "services/campaign/types.response";
import { ICampaignPlanItemResponse, ICurrencyResponse } from "services/campaign/types";
import { IPlatformMaster } from "services/master/types";
import { IActualBugetsRequest } from "./types";
import { IActualCampaignFormData } from "components/brief/types";

export interface IBriefDetailsResponse {
    id: number;
    agencyId: number;
    agency: IResponseDDL;
    statusId: Status;
    contact?: IBriefContactResponse;
    campaignDetail?: ICampaignResponse;
    spentByObjectives?: ISpentByObjectiveResponse[];
    targettingInstruction?: ITargettingResponse[];
    purchaseOrders?: IPurchaseOrderResponse; //common for both of them
    insertionOrders?: IInsertionOrderResponse; //common for both of them
    digitalAssets?: IDigitalAssetsResponse[]; //common for both of them
    implementationPlans?: IImplementationPlanResponse; //for agency
    campaignStatus?: ICampaignStatusResponse; //for agency
    campaignLiveProof?: IAcknowldgeLiveResponse[]; //for agency
    fundings?: IFundingResponse; //for client successor
    campaignTracker?: ICampaignTrackerResponse; //for client successor
    assignOrValidation?: IAssignOrValidationResponse; //for client successor
    campaignAssigns?: ICampaignAssignResponse[]; // for Adops
    campaignSetups?: ICampaignSetupResponse[]; // for Adops
    dateCreated?: string;
    dateCompleted?: string;
    // campaignLiveProof?: IAcknowldgeLiveResponse;
    allCompleted?: boolean; //not mapped
    status?: IResponseDDL;
    formListId?: number;
    notes?: INotesResponse[]; //for all dept
}

export interface IInsertionOrderResponse {
    briefId: number;
    id?: number;
    ioCode: string;
    statusId: number;
    status: IResponseDDL;

    approvedIoFileId?: string;
    approvedIoFile?: IFileDetails;
    signedIoFileId?: string;
    signedIoFile?: IFileDetails;
    ioApprovedDate?: string;
    ioSignedDate?: string;
    ioLasteRemindedDate?: string;

    successStatusId?: number;
    agencyStatusId?: number;
    successStatus: IResponseDDL;
    agencyStatus: IResponseDDL;
    dateCompleted?: string;
    insertionOrderHistory: IHistoryResponse[];

    version?: number;
    thirdPartyFile?: IFileDetails;
}
export interface IPurchaseOrderResponse {
    cmpId: number;
    statusId: number;
    value: number;
    status: IResponseDDL;
    successStatus?: IResponseDDL;
    agencyStatus?: IResponseDDL;
    successStatusId?: number;
    agencyStatusId?: number;
    purchaseOrderHistory: IHistoryResponse[];
    dateCompleted?: string;
    id?: number;
}
export interface IDigitalAssetsResponse {
    dateCompleted?: string;
    notes: string;
    briefId: string;
    url: string;
    username: string;
    password: string;
    path: string;
    email: string;
    burstStartDate?: string;
    burstEndDate?: string;
    burstBudget?: number;
    platformId?: number;
    platform: IPlatformMaster;
    agencyId?: number;
    statusId: number;
    successStatus?: IResponseDDL;
    agencyStatus?: IResponseDDL;
    successStatusId?: number;
    agencyStatusId?: number;
    insertionOrderHistory: IHistoryResponse[];
    status: IResponseDDL;
    id?: number;
    digitalAssetsHistory: IHistoryResponse[];
}
// export interface IImplementationPlanResponse {
//     cmpId: number;
//     statusId: number;
//     value: number;
//     successStatusId?: number;
//     agencyStatusId?: number;
//     digitalAssetsHistory: IHistoryResponse[];
// }
export interface ICampaignStatusResponse {
    id: string;
    status: IResponseDDL;
    statusId: number;
    value: number;
    campaignUrl: string;
    campaignStatusHistory: IHistoryResponse[];
}
export interface IAcknowldgeLiveResponse {
    id: string;
    status: IResponseDDL;
    statusId: number;
    value: number;
    platformId?: number;
    campagignUrl: string;
    AcknowldgeLiveHistory: IHistoryResponse[];
    campaignLiveProofImage?: {
        fileUploadId: string;
        fileUpload: IFileDetails;
    }[];
}

export interface ICampaignTrackerResponse {
    cmpId: number;
    statusId: number;
    value: number;
    status: IResponseDDL;
    digitalAssetsHistory: IHistoryResponse[];
}
export interface IAssignOrValidationResponse {
    cmpId: number;
    statusId: number;
    value: number;
    successStatusId?: number;
    agencyStatusId?: number;
    digitalAssetsHistory: IHistoryResponse[];
}

export interface IBriefContactResponse extends IMediaFees {
    reportingAnalystId?: string;
    reportingAnalystEmail?: string;
    reportingAnalystName?: string;
    strategistId: string;
    strategistName: string;
    strategistEmail: string;
    strategistContactNo: string;
    ioSigningPersonId: string;
    ioSigningPersonName: string;
    ioSigningPersonEmail: string;
    ioSigningPersonAlternateId: string;
    ioSigningAlternatePerson: string;
    ciIssuingPersionEmail: string;
    ciIssuingPersonId: string;
    ciIssuingPersonName: string;
    sendReportToEmail: string;
    poRequired: PoRequiredType;
    poNumber?: string;
    poAmount?: number;
    reportFrequency: string;
    notes?: string;
    id?: number;
    fileUpload?: IFileDetails;
}

export enum PoRequiredType {
    NoPORequired = 2,
    POPending = 3,
    AttachPo = 1,
}

export interface ICampaignResponse extends ICampaignBudgetDetails {
    briefId: number;
    client: IResponseDDL;
    brand: IResponseDDL;
    campaignName: string;
    notes?: string;
    startDate: string;
    endDate: string;
    id?: number;
    dateCreated?: string;
    isDeleted?: boolean;
    campaignMonthlyBugets?: ICampaignMonthlyBugetsResponse[];
    campaignPlan?: {
        campaignDetailId?: number;
        statusId: Status;
        campaignPlanItems: ICampaignPlanItemResponse[];
    };
    nexusCampaignNumber: string;
    reportingAdditionalNotes?: string;
    reportingUseOfUnderSpends?: boolean;
    reportingDeadlineDate?: string;
    actualMonthlyBudget?: IActualBugetsRequest[];
    currency?: IResponseDDL;
}

export interface ISpentByObjectiveResponse {
    fanPageUrl: string;
    isMoveBudget: boolean;
    frequencyLimit: string;
    id: number;
    isDeleted: boolean;
    platform: IPlatformMaster;
    spentByObjectiveAndBudgets: IObjectiveRowsResponse[];
    canMoveBudgetBetween: boolean;

    startDate?: string;
    endDate?: string;

    //Spent Tracker tab
    spent?: number;
    spentNotes?: string;
    isThirdParty?: boolean;
    isNew?: boolean;
}

export interface ISpentByObjectiveHistoryResponse extends IHistoryResponse {
    spent?: number;
}

export interface IReportingTabHistoryResponse extends IHistoryResponse {
    addedByName: string;
    dateCreated: string;
    deadlineReport: string;
    notes: string;
    useOfUnderSpends?: boolean;
    reportingObjectiveHistory: IReportingObjectiveHistory[];
    fileName?: string;
    fileUploadId?: string;
}
export interface IMediaFees<T = number> {
    taagFee?: T;
    dv360TechFee?: T;
    agencyFee?: T;
    taggingFee?: T;
    leviesGhsFee?: T;
    ghsFee?: T;
}
export interface IReportingObjectiveHistory {
    platform: IResponseDDL;
    platformObjective: IResponseDDL;
    reportingNotes: string;
    reportingRequirements: string;
}
export interface ITargettingResponse {
    platform: IResponseDDL;
    objective: IResponseDDL;
    age: string;
    gender: string;
    location: string;
    interest: string;
    notes: string;
    id: number;
    isDeleted: boolean;
}
export interface IResponseDDL {
    name: string;
    id: number;
    description?: string;
    isStandAlone?: boolean;
    currencyId?: number;
    symbol?: string;
    shortCode?: string;
}

export interface ICampaignMonthlyBugetsResponse {
    startDate: string;
    endDate: string;
    budget: number;
    campaignDetailId?: number;
    id?: number;
    isDeleted?: boolean;
}

export interface IObjectiveRowsResponse {
    platformId?: string;
    platformName?: string;
    platformObjective?: IResponseDDL;
    budget?: number;
    benchMark?: string;
    kpi?: string | number;
    notes?: string;
    id?: number;
    isDeleted?: boolean;
    reportingNotes?: string;
    reportingRequirements: string;

    brandSafety?: string;
    adServing?: string;
    isTagging?: boolean;
    taggingDocument?: IFileDetails;
    taggingDocumentId?: string;
    platformAgencyFee?: number;
    sA360?: number;
}

export interface ICampaignAssignResponse {
    assignedAdmin: IUserDDL;
    assignedUser: IUserDDL;
    id: number;
    status: IResponseDDL;
    statusId: number;
    notes?: string;
    dateCreated: string;

    addedById?: string;
    briefId?: number;
    campaignDetailId?: number;
    assignedAdminId?: string;
    assignedUserId?: string;
    isDeleted?: boolean;

    platformId?: number;
}

export interface ICampaignBudgetDetails {
    totalCampaignBudget: number;
    isMonthlyBudget: boolean;
    grandTotal?: number;
    totalAdServingBudget?: number;
    totalBrandSafetyBudget?: number;
    totalNonMediaFees?: number;
    totalPlatformAgencyFee?: number;
    totalSA360?: number;
}

export interface ITable1ItemResponse {
    key1?: string;
    value1?: string;
    key2?: string;
    value2?: string;
    platformOrObjective?: string;
    startDate?: string;
    endDate?: string;
    kpi: "-";
    targetting?: string;
    budget?: string;
}

export interface INotesResponse {
    dateCreated?: string;
    addedById?: string;
    briefId?: number;
    campaignDetailId?: number;
    comments?: string;
    addedByName?: string;
    agencyId: number;
    statusId: number;
    id: number;
    agency: IResponseDDL;
}
