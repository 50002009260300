import axios from "axios";
import { toast } from "react-toastify";
import { IUser } from "services/user/types";
import { errorToast, getStorageValue, StorageKey } from "utility";

function getAuthHeader() {
    let user = getStorageValue<IUser>(StorageKey.User);
    if (user && user.token) {
        return {
            Authorization: `Bearer ${user.token}`,
        };
    } else {
        return {};
    }
}
export const Ajax = {
    getRequest: function <T>(url: string, params: any) {
        return axios.get<T>(url, {
            method: "get",
            params: { ...params },
        });
    },
    postRequest: function <T>(url: string, params: any, isFile?: boolean, isDownload?:boolean) {
        let config: any = {
            headers: {
                ...getAuthHeader(),
            },
        };
        if (isFile) {
            config.headers["Content-Type"] = "multipart/form-data";
        }
        if(isDownload){
            config["responseType"] = "arraybuffer";
        }
        if (isFile) {
            return axios.post<T>(url, params, config);
        } else {
            let req = axios.post<T>(url, params, config);
            req.catch((e) => {
                if (e.response && e.response.status === 401) {
                    let data = e.response.data;
                    setTimeout(() => {
                        toast.dismiss();
                        if (data.error) {
                            errorToast(data.error);
                        } else {
                            errorToast(data);
                        }
                    }, 0);
                }
            });
            return req;
        }
    },
};

export interface IApiPagedResponse<T> {
    data: IPagedResponse<T>;
    success: boolean;
    error?: string;
}
export interface IApiResponse<T> {
    data: T;
    success: boolean;
    error?: string;
}

export interface IPagedResponse<T> {
    results: T;
    rowCount: number;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    firstRowOnPage?: number;
    lastRowOnPage?: number;
}
