export enum Status {
    Empty = 0,
    New = 1,
    InProgress = 2,
    SendForApprovalForAdmin = 3,
    SendForApprovalForClient = 4,
    Approved = 5,
    IOCodeGeneration = 6,
    IOUpload = 7,
    Rejected = 8,
    Closed = 9,
    Created = 10,
    IntermediateUpload = 11,
    ReminderOnDigitalAssetToClient = 12,
    RequestForCurrentStatus = 13,
    Approve = 14,
    Reject = 15,
    RequestForCurrentStatusOnLive = 16,
    Acknowledge = 17,
    InReview = 18,
    ReminderForCompletion = 19,
    Review = 20,
    GenerateFundingCode = 21,
    Assign = 22,
    InActive = 23,
    ReminderOnDigitalAssetToAgency = 24,
    DigitalAssetsReminder = 25,
    AssignToCampaignManager = 26,
    OnHold = 27,
    SubmitForReviewInternally = 28,
    ReviewSetUp = 29,
    SentToAgencyForApproval = 30,
    LiveApprovalProof = 31,
    Draft = 32,
    Delayed = 33,
    CampaignLive = 34,
    BriefCompleted = 35,
    AssetsRequested = 36,
    IOToSign = 37,
    Completed = 38,
    PoUpload = 39,
    IOReminder = 40,
    POReminder = 41,
    CaptureLiveProof = 42,
    IoRevision = 43,
    Updated = 44,
    CampaignEnded = 45,
    ReminderOnCampaignSetup = 46,
    ReminderOnProofOfLive = 47,
    ReminderOnSpentTracker = 48,
    Uploaded = 49,
}
