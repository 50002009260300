import { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { DragDrop } from "@uppy/react";
import { FieldColumnLayout, getRequiredLabel } from "./common";
import { IFileMetaData, IFileUploader } from "./types";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@core/scss/react/libs/file-uploader/file-uploader.scss";
import prettierBytes from "@transloadit/prettier-bytes";
import { errorToast } from "utility";
import { FileService, IFileDetails } from "services/file/file-service";
import { Col, FormFeedback } from "reactstrap";
import { getErrorAndValue } from "./utils";
import { X } from "react-feather";
import { ApiPaths } from "constants/common";
import pdfIcon from "assets/images/icons/file-icons/pdf-icon.png";
import noImage from "assets/images/no-image.png";

function CustomUploader<T>({ id, form, autoUpload, fileMetaData, allowedFiles, removeOnlyNew, isViewOnly, ...props }: IFileUploader<T>) {
    autoUpload = autoUpload === undefined ? true : autoUpload;
    const [previewArr, setPreviewArr] = useState<Record<string, string | undefined>>({});
    // const [files, setFiles] = useState<IFileData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<IFileDetails[]>([]);
    // const [uploadedFileName, setUploadedFileName] = useState<Record<string, IFileDetails | undefined>>();
    let label = getRequiredLabel(props.required, props.label);

    let { touchValue, errorValue, selectedValue } = getErrorAndValue(id, form);

    let selectedFile: IFileDetails | IFileDetails[] = selectedValue;

    // let selectedFiles: IFileDetails[] = selectedValue; // ? (Array.isArray(selectedValue) ? selectedValue : [selectedValue]) : [];
    const canShowError = (touchValue || form.isSubmitting) && errorValue;

    useEffect(() => {
        if (!selectedValue) {
            // setFiles([]);
            setPreviewArr({});
            // setUploadedFileName({});
        } else {
            if (selectedFile) {
                if (Array.isArray(selectedFile)) {
                    if (selectedFile.length === 1 && !selectedFile[0].fileName) {
                        setIsLoading(true);
                        FileService.getDetailsById(selectedValue)
                            .then((a) => {
                                if (a.data.success) {
                                    setSelectedFiles([a.data.data]);
                                }
                            })
                            .finally(() => setIsLoading(false));
                    } else {
                        setSelectedFiles(selectedFile);
                    }
                } else {
                    setSelectedFiles([selectedFile]);
                }
            } else {
                setSelectedFiles(selectedValue);
            }
        }
    }, [selectedValue]);

    const uppy = Uppy({
        meta: { type: "avatar" },
        autoProceed: autoUpload,
        allowMultipleUploads: !!props.isMulti,
        restrictions: {
            allowedFileTypes: allowedFiles,
            maxNumberOfFiles: props.isMulti ? null : 1,
        },
        // onBeforeUpload: (files) => {
        //     if (!props.isMulti && Object.keys(files).length > 1) {
        //         errorToast("Only one file is allowed");
        //         return false;
        //     }
        //     return true;
        // },
    });
    const onFileAdded = (file: IFileData[]) => {
        // setFiles((p) => [...p, ...file]);
        // setUploadedFileName(undefined);
        let postdata = new FormData();
        file.forEach((f) => {
            postdata.append("files", f.data);
        });
        addMetaData(fileMetaData, postdata);

        if (autoUpload) {
            setIsLoading(true);
            FileService.upload(postdata)
                .then((res) => {
                    if (res.data.success) {
                        let data = res.data.data;
                        if (Array.isArray(data)) {
                            let fData = data;
                            file.forEach((f, i) => {
                                fData[i].fid = f.id;
                            });
                            if (props.isMulti) {
                                // let response = data;
                                let ext = (form.values as any)[id] || [];
                                form.setFieldValue(id, [...ext, ...fData]);
                            } else {
                                form.setFieldValue(id, fData[0]);
                            }
                            // let fileName = { ...uploadedFileName };
                            // file.forEach((f, i) => {
                            //     fileName[f.id] = response[i];
                            // });
                            // setUploadedFileName((prev) => ({ ...prev, ...fileName }));
                        }
                    } else {
                        errorToast("Error while uploading. Please try again later");
                    }
                })
                .catch(() => {
                    errorToast("Error while uploading. Please try again later");
                })
                .finally(() => setIsLoading(false));
        } else {
            form.setFieldValue(id, file);
        }
    };

    function removeTempFile(fid: IFileDetails, isPreview?: boolean) {
        let selectedIndex = selectedFiles.findIndex((a) => a.fid === fid.fid);
        if (selectedIndex !== -1) {
            let fileToRemove = selectedFiles[selectedIndex];
            if (fileToRemove) {
                setIsLoading(true);
                FileService.removeFile(fileToRemove.id)
                    .then((res) => {
                        if (res.data.success) {
                            let newList = [...selectedFiles];
                            newList.splice(selectedIndex, 1);
                            form.setFieldValue(id, newList);
                            // setFiles(files.filter((a) => a.id !== fid));
                            if (fileToRemove.fid) {
                                uppy.removeFile(fileToRemove.fid);
                            }
                            // setUploadedFileName((prev) => ({ ...prev, [fid]: undefined }));
                            if (isPreview && fid.fid) {
                                let updatedPrv = { ...previewArr };
                                delete updatedPrv[fid.fid];
                                setPreviewArr(updatedPrv);
                            }
                        } else {
                            errorToast("Error while removing the file. Please try again later");
                        }
                    })
                    .catch(() => {
                        errorToast("Error while removing the file. Please try again later");
                    })
                    .finally(() => setIsLoading(false));
            }
        }
    }
    if (props.isMulti) {
        uppy.on("files-added", onFileAdded);

        const renderPreview = () => {
            let keys = Object.keys(previewArr);
            if (keys.length) {
                return (
                    <div className="d-flex">
                        {keys.map((key, index) => (
                            <div className={"rounded mt-2 mr-1 image-thumbnail"} key={key}>
                                {/* <span className={"image-thumbnail-close"} onClick={() => removeTempFile(key, true)}>
                                    <X size="16" />
                                </span> */}
                                <img className="" src={previewArr[key]} alt="avatar" style={{ width: "150px" }} />
                            </div>
                        ))}
                    </div>
                );
            } else {
                return null;
            }
        };

        return (
            <>
                <FieldColumnLayout label={label} sm={props.sm} md={props.md} lg={props.lg} noColumn={props.noColumn}>
                    {!isViewOnly && (
                        <div className={errorValue ? "is-invalid" : ""}>
                            <DragDrop uppy={uppy} />
                            {}
                        </div>
                    )}
                    {canShowError && <FormFeedback>{errorValue}</FormFeedback>}
                    {isLoading ? <div>Loading...</div> : renderPreview()}

                    {selectedFiles && selectedFiles.length > 0 && (
                        <Col sm="12 pl-0 pr-0 d-flex">
                            {selectedFiles.map((im, index) => {
                                const filePath = ApiPaths.File.downloadLink + im.id;
                                const iconSource = im.fileType === "application/pdf" ? pdfIcon : filePath;
                                return (
                                    <div className={"rounded mt-2 mr-1 image-thumbnail"} key={index}>
                                        <div>
                                            {((removeOnlyNew && im.fid) || !removeOnlyNew) && !isViewOnly && (
                                                <span className={"image-thumbnail-close"} onClick={() => removeTempFile(im, true)}>
                                                    <X size="16" />
                                                </span>
                                            )}
                                            <a href={filePath} target="_blank" rel="noreferrer">
                                                <img
                                                    className=""
                                                    src={iconSource}
                                                    alt="avatar"
                                                    style={{ width: "150px" }}
                                                    onError={(e: any) => {
                                                        e.target.onerror = null;
                                                        e.target.src = noImage;
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <div className="file-name-bg">
                                            <span title={im.fileName} className="cut-string pl-1 pr-1">
                                                {im.fileName}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </Col>
                    )}
                </FieldColumnLayout>
            </>
        );
    } else {
        uppy.on("file-added", (file: IFileData) => {
            onFileAdded([file]);
        });
        uppy.on("upload-progress", (file, progress) => {});
    }
    return (
        <>
            <FieldColumnLayout
                className="single-upload"
                label={label}
                sm={props.sm || "4"}
                md={props.md}
                lg={props.lg}
                noColumn={props.noColumn}
            >
                <div className={errorValue ? "is-invalid" : ""}>
                    {selectedFiles && selectedFiles.length > 0 ? (
                        <>
                            <div className={`form-control ${isViewOnly ? "disabled" : ""}`}>
                                {selectedFiles.map((file) => (
                                    <div key={file.id} className="uploaded-file">
                                        <div className="file-name-div" title={file.fileName}>
                                            {file.fileName}
                                        </div>
                                        {autoUpload && !isViewOnly && (
                                            <span className="uploaded-file-remove" onClick={() => removeTempFile(file)}>
                                                <X size="16" />
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {prettierBytes(selectedFiles[0].fileSize)}
                        </>
                    ) : (
                        <div className={isViewOnly ? "disable-upload" : ""}>
                            <DragDrop uppy={uppy} />
                        </div>
                    )}
                </div>
                {canShowError && <FormFeedback>{errorValue}</FormFeedback>}
                {isLoading && <div>Loading...</div>}
            </FieldColumnLayout>
        </>
    );
}

export default CustomUploader;

export interface IFileData {
    id: string;
    data: File;
    name: string;
    extension: string;
    size: number;
}
export function addMetaData(fileMetaData: IFileMetaData | undefined, postdata: FormData) {
    if (fileMetaData) {
        const metaKeys = Object.keys(fileMetaData);
        metaKeys.forEach((fItem, fIndex) => {
            postdata.append(fItem, (fileMetaData as any)[fItem]);
        });
    }
}
