import { RawDraftContentState } from "draft-js";
import { ReactNode } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { CustomEditorInputWithForm } from "./custom-editor";
// import Cleave from "cleave.js/react";

interface INotesPopupProps {
    notes: string | undefined;
}

const notesState = atom<string | undefined | ReactNode>({
    default: undefined,
    key: "notesState",
});
const getJson = (text: string) => {
    try {
        return JSON.parse(text);
    } catch {
        return null;
    }
};
const getDefaultText = (ss: RawDraftContentState) => {
    if (ss.entityMap && Object.keys(ss.entityMap).length === 0 && ss.blocks.length === 1) {
        const text = ss.blocks.filter((a) => a.text && a.type === "unstyled")[0];
        return text.text ?? undefined;
    }
    return undefined;
};
export function NotesPopupLink({ notes }: INotesPopupProps) {
    const setNote = useSetRecoilState(notesState);
    const showPop = () => {
        setNote(<CustomEditorInputWithForm hideToolbar sm="12" disabled id={"notes"} value={notes} />);
    };
    if (notes) {
        const ss: RawDraftContentState = getJson(notes);
        if (ss && Object.keys(ss).length > 0) {
            const text = getDefaultText(ss);
            if (text) return <div style={{ whiteSpace: "pre-line" }}>{text}</div>;
            return (
                <>
                    {notes && (
                        <span className="notes-show-link" onClick={showPop} title="Click here to see the full message">
                            Show
                        </span>
                    )}
                </>
            );
        }
    }
    return <div style={{ whiteSpace: "pre-line" }}>{notes}</div>;
}

interface IShowAsPopupProps {
    children: ReactNode;
}
export function ShowAsPopup({ children }: IShowAsPopupProps) {
    const setNote = useSetRecoilState(notesState);
    const showPop = () => {
        setNote(children);
    };

    return (
        <span className="notes-show-link" onClick={showPop} title="Click here to see the full message">
            Show
        </span>
    );
}

export function NoteModal() {
    const [notes, setNotes] = useRecoilState(notesState);
    const onClose = () => {
        setNotes(undefined);
    };
    return (
        <>
            {notes && (
                <Modal isOpen={true} backdrop={true} toggle={onClose} onClosed={onClose} className="modal-dialog-centered modal-lg">
                    <ModalHeader>Notes - Details</ModalHeader>
                    <ModalBody>{notes}</ModalBody>
                    <ModalFooter>
                        <Button color="primary center" onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}
